<template>
  <div>
    <iframe
      ref="iframe"
      :src="url"
      class="h-100 w-100"
      :class="{'d-none': !validInteractive, 'hidden': !data}"
      frameborder="0"
      height="100%"
      width="100%"
    />
    <div
      v-if="problem && !data && loaded"
      class="container mt-5 text-center text-danger"
    >
      <h1>Chyba pri načítavaní interaktívky</h1>
      <b-button href="/">
        Domov
      </b-button>
    </div>
  </div>
</template>

<script>
import { apiProblems } from 'frontend-common'
import constants from '@/constants'

export default {
  name: 'Interactive',
  components: {},
  mixins: [
    apiProblems,
  ],
  props: {},
  data() {
    return {
      problem: null,
      data: null,
      loaded: false,
      constants,
    }
  },
  computed: {
    validInteractive() {
      return !!this.problem?.interactive
    },
    url() {
      if (!this.data) return ''

      if (!this.data.frontend_url.startsWith('https://') &&
        !this.data.frontend_url.startsWith('http://')) {
        return `https://${this.data.frontend_url}`
      }

      return this.data.frontend_url
    },
  },
  watch: {
    '$route.params.problemId': {
      handler: async function (problemId) {
        this.loaded = false
        this.problem = await this.apiProblem(problemId)
        if (!this.problem.interactive) return

        if (this.$root.stateLoaded && !this.$root.state.outdatedProfile) {
          this.data = await this.apiInteractiveState(this.problem.interactive)
        }
        this.addIframeHooks()
        this.loaded = true
      },
      deep: true,
      immediate: true,
    },
    '$root.state.outdatedProfile': {
      handler: async function (outdated) {
        if (!outdated) return

        await this.$root.dangerModalOk(
          'Neaktuálne údaje',
          'Počas tohto školského roku si si ešte neskontroloval/-a aktuálnosť osobných údajov.' +
            ' Budeš presmerovaný/-á na stránku, kde si ich môžeš skontrolovať.',
        )
        await this.$router.push({name: 'profile-change'})
      },
    },
    '$root.stateLoaded': {
      handler: async function (loaded) {
        if (!loaded) return
        if (!this.data && !this.$root.state.outdatedProfile) {
          this.data = await this.apiInteractiveState(this.problem.interactive)
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    addIframeHooks() {
      const w = this.$refs.iframe.contentWindow

      window.addEventListener('message', ({data}) => {
        switch(data.message) {
        case 'initialized':
          if (!this.$root.storage.token) return

          w.postMessage({
            type: 'userAuthData',
            data: {
              token: this.$root.storage.token,
              domain: constants.backendUrl,
              problemId: this.problem.interactive,
            }}, '*')
          break
        case 'ExitInteractive':
          this.$router.push(`/ulohy/${this.problem.round}#uloha-${this.problem.number}`)
          break
        case 'RedirectToLogin':
          this.$router.push({name: 'home', params: {openLogin: true}})
          break
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

div {
  position: fixed;
  inset: 0;
}

.hidden {
  opacity: 0;
}

</style>
